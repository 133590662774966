// src/pages/PersonalProjects.js
import React from 'react';
import Project from '../components/Project';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import projectsData from '../data/projects';
import './PersonalProjects.css'; // Custom styles for slick arrows

const PersonalProjects = () => {
  return (
    <div className="personal-projects p-4">
      {Object.entries(projectsData)
        .filter(([id, project]) => project.type === 'personal') // Filter for personal projects
        .map(([id, project]) => (
          <Project
            key={id}
            id={id}
            title={project.title}
            images={project.images}
            description={project.shortDescription}
            tags={project.tags}
            type={project.type} // Include type to display project type label
            dateRange={project.dateRange} // Add dateRange prop
          />
        ))}
    </div>
  );
};

export default PersonalProjects;
