// src/components/Project.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const Project = ({ id, title, images, description, tags, type, dateRange }) => {
  const settings = {
    dots: true,
    infinite: images.length > 1, // Disable infinite scrolling if there's only one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand', // Add lazy loading to optimize image loading
    autoplay: true, // Enable auto rotation of slides
    autoplaySpeed: 3000, // Set auto rotation speed in milliseconds (3 seconds)
    arrows: true, // Add navigation buttons for manual control
  };
  

  return (
    <Link to={`/projects/${id}`} className="block hover:bg-gray-100 rounded transition">
      <div className="flex flex-col md:flex-row mb-8">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <Slider {...settings}>
            {images.map((img, index) => (
              <div key={index}>
                <img
                  src={img}
                  alt={`${title} screenshot ${index + 1}`}
                  className="w-full h-auto"
                  loading="lazy" // Additional lazy load hint for browsers that support it
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="w-full md:w-1/2 p-4">
  <h2 className="text-xl font-semibold mb-2">{title}</h2>
  <p className="text-sm text-gray-600 mb-2">{dateRange}</p> {/* Display the date range */}
  <div className="project-description mb-4" dangerouslySetInnerHTML={{ __html: description }} /> {/* Render HTML in description */}
  <div className="flex flex-wrap gap-2">
    {tags.map((tag, index) => (
      <span key={index} className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
        {tag}
      </span>
    ))}
    <span className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${type === 'personal' ? 'bg-green-200 text-green-800' : 'bg-purple-200 text-purple-800'}`}>
      {type === 'personal' ? 'Personal Project' : 'School Project'}
    </span>
  </div>
</div>

      </div>
    </Link>
  );
};

export default Project;