// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <h1 className="text-2xl font-bold">
        <Link to="/">Andrew Viola</Link> {/* Link to home when clicking on the name */}
      </h1>
      <ul className="flex space-x-4">
        <li><Link to="/personal-projects" className="hover:underline">Personal Projects</Link></li>
        <li><Link to="/school-projects" className="hover:underline">School Projects</Link></li>
        <li><Link to="/contact" className="hover:underline">Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Header;
