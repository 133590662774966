// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import PersonalProjects from './pages/PersonalProjects';
import SchoolProjects from './pages/SchoolProjects';
import Contact from './components/Contact';
import ProjectDetail from './pages/ProjectDetail';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/personal-projects" element={<PersonalProjects />} />
            <Route path="/school-projects" element={<SchoolProjects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects/:id" element={<ProjectDetail />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;