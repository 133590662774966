// src/components/Contact.js
import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <form className="max-w-lg mx-auto p-4 bg-gray-100 shadow-md rounded" onSubmit={handleSubmit}>
      <label className="block mb-2">
        Name:
        <input type="text" name="name" value={formData.name} onChange={handleChange} required className="w-full p-2 border rounded mb-4" />
      </label>
      <label className="block mb-2">
        Email:
        <input type="email" name="email" value={formData.email} onChange={handleChange} required className="w-full p-2 border rounded mb-4" />
      </label>
      <label className="block mb-2">
        Message:
        <textarea name="message" value={formData.message} onChange={handleChange} required className="w-full p-2 border rounded mb-4" />
      </label>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Send</button>
    </form>
  );
};

export default Contact;