// src/pages/Home.js
import React from 'react';
import Project from '../components/Project';
import projectsData from '../data/projects';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PersonalProjects.css'; // Custom styles for slick arrows

const Home = () => {
  
  return (
    <div className="home-projects p-4">
      {Object.entries(projectsData).map(([id, project]) => (
        <div key={id} className="mb-8">
          <Project
            id={id}
            title={project.title}
            images={project.images}
            description={project.shortDescription}
            tags={project.tags}
            type={project.type}
            dateRange={project.dateRange} // Add dateRange prop
          />
        </div>
      ))}
    </div>
  );
};

export default Home;
