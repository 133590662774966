// src/pages/ProjectDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../data/projects';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PersonalProjects.css'; // Custom styles for slick arrows

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projectsData[id];

  if (!project) {
    return <div className="p-4 text-red-500">Project not found. Please check if the project ID is correct.</div>;
  }

  const settings = {
    dots: true,
    infinite: project.images.length > 1, // Disable infinite scrolling if there's only one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{project.title}</h1>
      <p className="text-sm text-gray-600 mb-4">{project.dateRange}</p> {/* Display the date range */}
      <Slider {...settings} className="mb-8">
        {project.images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`${project.title} screenshot ${index + 1}`} className="w-full h-auto" />
          </div>
        ))}
      </Slider>
      <div className="project-description mb-4" dangerouslySetInnerHTML={{ __html: project.description }} /> {/* Render HTML in description */}

      <div className="flex flex-wrap gap-2">
        {project.tags.map((tag, index) => (
          <span key={index} className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
            {tag}
          </span>
        ))}
        <span className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${project.type === 'personal' ? 'bg-green-200 text-green-800' : 'bg-purple-200 text-purple-800'}`}>
          {project.type === 'personal' ? 'Personal Project' : 'School Project'}
        </span>
      </div>
    </div>
  );
};

export default ProjectDetail;
