// src/data/projects.js

const importAllImages = (r) => r.keys().map(r);

// Importing images for each project
const laserPoster = importAllImages(require.context('../assets/images/laserPoster', false, /\.(png|jpe?g|svg)$/));


const projectsData = {
  1: {
    title: 'Laser Engraved LED Picture Frame',
    images: laserPoster,
    shortDescription: 'Simple wooden poster that evolved into a stunning LED-lit picture frame, blending laser engraving, custom 3D printing, and DIY electronics.',
    description: `Originally starting as simple laser engraved wooden posters, it evolved to become a picture frame.  The original artwork is hand traced in Inkscape in order to get a vectorized image of the poster.  That image is laser engraved onto an 11" x 11" piece of wood.  The image is then mirrored and laser engraved again onto an 11" x 11" piece of acrylic.  The acrylic is mirrored so when it is turned around and placed on top of the wood, you cannot feel the engraved lines.<br></br>
                  The width of the wood and acrylic were measured and a frame was 3D modeled in Fusion 360.  The frame is printed in four pieces along with four corners, all printed upright to avoid overhangs.  An LED strip is strung through the frame and dupont connectors are crimped to the LED strip in order for it to be easily rearranged for a college dorm room.  A custom PCB was CNC'd out of a single layer copper FR4 board.  This was done to make wiring neater and as a proof of concept.<br></br>
                  With the LEDs off, the wood engraving is shown.  But with the LEDs on, the acrylic engraving is lit, and shows off the detailed linework that was engraved.`,
    tags: ['3D Printing', 'Laser Cutting', 'CAD', 'PCB Design', 'KiCAD', 'WLED'],
    type: 'personal',
    dateRange: 'Fall 2022'
  },
};

export default projectsData;